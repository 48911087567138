import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPrefixes, { getCountries } from '../../../constants/phonePrefixes';
import { Organization } from '../../../types/entities/organization';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import InputText from '../../ui/formComponents/inputText/InputText';
import Select from '../../ui/formComponents/select/Select';
import './styles.scss';

type Props = {
  editInvoicingData: (invoicingData: InvoicingDataType) => void;
  organization: Organization;
  buttonText: string;
};
function EditInvoicingData({ editInvoicingData, organization, buttonText }: Props) {
  const { t, i18n } = useTranslation();

  const [tradeName, settradeName] = useState(organization.invoicing_trade_name || '');
  const [vat, setVat] = useState(organization.vat || '');
  const [address, setAddress] = useState(organization.invoicing_street_address || '');
  const [country, setCountry] = useState(
    organization.invoicing_country
      ? { id: organization.invoicing_country, name: organization.invoicing_country }
      : { id: '', name: '' }
  );
  const [province, setProvince] = useState(organization.invoicing_province || '');
  const [postalCode, setPostalCode] = useState(organization.invoicing_postal_code || '');
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const onChangetradeName = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'tradeName');
    setErrors(newErrors);
    settradeName(e.target.value);
  };

  const onChangeVat = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'vat');
    setErrors(newErrors);
    setVat(e.target.value);
  };

  const onChangeAddress = (e: ChangeEvent<HTMLInputElement>): void => {
    const newErrors = errors.filter((elem) => elem.error !== 'address');
    setErrors(newErrors);
    setAddress(e.target.value);
  };

  const onChangeCountry = (value: SelectOptionFormat) => {
    const newErrors = errors.filter((elem) => elem.error !== 'country');
    setErrors(newErrors);
    setCountry(value);
  };

  const onChangeProvince = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'province');
    setErrors(newErrors);
    setProvince(e.target.value);
  };

  const onChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
    const newErrors = errors.filter((elem) => elem.error !== 'postalCode');
    setErrors(newErrors);
    setPostalCode(e.target.value);
  };

  const handleEditInvoicingData = async () => {
    const newErrors: ErrorType[] = [];

    if (!tradeName) {
      newErrors.push({ error: 'tradeName' });
    }

    if (!vat) {
      newErrors.push({ error: 'vat' });
    }

    if (!address) {
      newErrors.push({ error: 'address' });
    }

    if (!country.id) {
      newErrors.push({ error: 'country' });
    }

    if (!province) {
      newErrors.push({ error: 'province' });
    }

    if (!postalCode) {
      newErrors.push({ error: 'postalCode' });
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      setLoadingButton(true);
      const invoicingData: InvoicingDataType = {
        invoicing_trade_name: tradeName,
        vat,
        invoicing_street_address: address,
        invoicing_country: country.id,
        invoicing_province: province,
        invoicing_postal_code: postalCode
      };

      await apiFetch('patch', `/organizations/${organization.id}`, {
        ...invoicingData,
        country: organization.country // country is sent because it is needed to validate the vat in case it changes
      });

      editInvoicingData(invoicingData);

      setLoadingButton(false);
    } catch (error: any) {
      const newErrors = [...errors];
      if (
        error?.response?.data?.detail.find((elem: any) =>
          elem.msg.includes('INVALID_POSTAL_CODE_PASSED')
        )
      ) {
        newErrors.push({ error: 'postalCode', description: t('error.invalidPostalCode') });
      }
      if (
        error?.response?.data?.detail.find((elem: any) => elem.msg.includes('INVALID_VAT_PASSED'))
      ) {
        newErrors.push({ error: 'vat', description: t('error.invalidVat') });
      }
      setErrors(newErrors);
      setLoadingButton(false);
    }
  };

  return (
    <div className='edit-invoicing-data'>
      <h1 className='headline4-font'>{t('invoicingData.title')}</h1>
      <h2 className='subtitle3-font '>{t('invoicingData.description')}</h2>
      <div className='form-wrapper'>
        <InputText
          icon={'/images/icons/building.svg'}
          label={t('invoicingData.tradeName')}
          placeholder={t('invoicingData.tradeNamePlaceholder')}
          value={tradeName}
          onChangeValue={onChangetradeName}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'tradeName')}
        />
        <InputText
          icon={'/images/icons/creditcard.svg'}
          label={t('invoicingData.vat')}
          placeholder={t('invoicingData.vatPlaceholder')}
          value={vat}
          onChangeValue={onChangeVat}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'vat')}
        />
        <InputText
          icon={'/images/icons/map.svg'}
          label={t('invoicingData.address')}
          placeholder={t('invoicingData.addressPlaceholder')}
          value={address}
          onChangeValue={onChangeAddress}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'address')}
        />
        <Select
          icon={'/images/icons/pin.svg'}
          placeholder={t('facilities.writeFacilityCountry')}
          label={t('facilities.country')}
          value={{
            id: country.id,
            name:
              getPrefixes(i18n.resolvedLanguage).find((prefix) => prefix.code === country.id)
                ?.name || country.id
          }}
          onChangeValue={onChangeCountry}
          height='31px'
          fontClass='input-small-font'
          size='small'
          options={getCountries(i18n.resolvedLanguage, organization ? organization.country : 'ES')}
          error={errors.find((elem) => elem.error === 'country')}
          sort={false}
        />
        <InputText
          icon={'/images/icons/directions.svg'}
          label={t('invoicingData.province')}
          placeholder={t('invoicingData.provincePlaceholder')}
          value={province}
          onChangeValue={onChangeProvince}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'province')}
        />
        <InputText
          icon={'/images/icons/compass.svg'}
          label={t('invoicingData.postalCode')}
          placeholder={t('invoicingData.postalCodePlaceholder')}
          value={postalCode}
          onChangeValue={onChangePostalCode}
          height='31px'
          fontClass='input-small-font'
          size='small'
          error={errors.find((elem) => elem.error === 'postalCode')}
        />
      </div>
      <div className='mt-8'>
        <Button
          lookAndFeel='primary'
          size='small'
          text={buttonText}
          onClick={handleEditInvoicingData}
          loading={loadingButton}
        />
      </div>
    </div>
  );
}

export default EditInvoicingData;
