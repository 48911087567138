import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../../layout/breadcrumb/Breadcrumb';
import SectionHeader from '../../../../layout/sectionHeader/SectionHeader';
import StepCarouselList from '../../../../ui/stepCarouselList/StepCarouselList';
import useStepCarousel from '../../../../ui/stepCarouselList/useStepCarousel';
import { DatapointsTable } from './components/datapointsTable/DatapointsTable';
import HeaderCard from './components/HeaderCard';
import useSteps from './hooks/useSteps';
import './styles.scss';
import DoubleMateriality from '../../pages/DoubleMateriality/DoubleMateriality';
import Environmental from '../../pages/Environmental/Environmental';
import HelpCTA from '../../../../ui/helpCTA/HelpCTA';
import Button from '../../../../ui/button/Button';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
const ESGMetricsDetail = () => {
  const { t } = useTranslation();
  // Obtain category from the URL param
  const { category } = useParams();
  const flags = useFeatureFlags();

  if (!category) {
    return null;
  }

  const steps = useSteps({ category });
  const parsedSteps = steps.map((step) => ({ ...step, title: t(step.text) }));

  const {
    stepSelected,
    handleSelect,
    steps: stepsParsed
  } = useStepCarousel({
    stepsText: parsedSteps
  });

  const handleGoToArticle = () => {
    window.open(
      'https://drive.google.com/file/d/1EF96LeDBYQO_CFq6ON2cfS1EuGnsymfX/view?usp=sharing',
      '_blank'
    );
  };
  if (category === 'double_materiality') {
    return <DoubleMateriality />;
  } else if (flags?.webinar241024 && category === 'environmental') {
    return <Environmental />;
  } else {
    return (
      <div className='esg-main-container'>
        <SectionHeader title={t('measureMain.esgMetrics')} breadcrumb={<Breadcrumb />} />
        {(!flags?.webinar241024 && category === 'environmental') ||
          category === 'social' ||
          (category === 'governance' && (
            <HelpCTA description={t('esgMetrics.help')}>
              <div className='flex'>
                <Button
                  lookAndFeel='primary'
                  text={t('esgMetrics.helpButton')}
                  size='tiny'
                  onClick={handleGoToArticle}
                />
              </div>
            </HelpCTA>
          ))}
        <HeaderCard keyTranslation={category} />
        {steps.length > 0 ? (
          <StepCarouselList steps={stepsParsed} handleSelect={handleSelect} />
        ) : (
          <div></div>
        )}
        <DatapointsTable category={category} subcategory={stepSelected?.id ?? ''} />
      </div>
    );
  }
};

export default ESGMetricsDetail;
