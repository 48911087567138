import { ReactNode, useEffect, useMemo, useRef } from 'react';
import './styles.scss';
import uuid from 'uuid';
import styles from './styles.module.scss';
import Icon from '../../icon/Icon';

type Props = {
  text: ReactNode;
  onChange: (value: boolean) => void;
  selected: boolean;
  error?: ErrorType;
  type?: 'circle' | 'square';
  color?: 'gradient' | 'gray';
  disabled?: boolean;
  style?: React.CSSProperties;
};

const OldCheckbox = ({
  text,
  onChange,
  selected,
  error,
  type = 'circle',
  color = 'gradient',
  disabled = false
}: Props) => {
  const handleCheckboxClick = () => {
    if (disabled) return;
    onChange(!selected);
  };

  let style = type === 'circle' ? 'checkbox-outer-circle' : 'checkbox-outer-square';
  let tick = '/images/icons/tick.svg';

  if (color === 'gray' || (disabled && !selected)) {
    style = style.concat(' ', 'gray');
    tick = '/images/icons/tickGray.svg';
  }

  let textColor = 'on-light-text-color';
  if (error) {
    textColor = 'error-text-color';
  }

  if (disabled && !selected) {
    textColor = 'disabled-text-color';
  }

  return (
    <>
      <div className='modern-checkbox-container'>
        <div className={`${style} ${!selected && 'unselected'}`} onClick={handleCheckboxClick}>
          {selected && (
            <div className='checkbox-icon-wrapper'>
              <img src={tick} alt='tick-selected' />
            </div>
          )}
        </div>
        <span className={`helper-text font-12 ${textColor}`}>{text}</span>
      </div>
    </>
  );
};

type PropsV2 = Omit<Props, 'color' | 'error'> & { indeterminate?: boolean };

export const Checkbox: React.FC<PropsV2> = ({
  text,
  onChange,
  selected,
  disabled = false,
  indeterminate = false,
  style = {}
}) => {
  const id = useMemo(() => uuid.v4(), []);

  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (disabled) return;
    onChange(!selected);
  };

  /* This useEffect is needed because for some reason HTML does not grant us the ability to change 
  indetermined directly from the input, we need to use javascript to do it. */
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [checkboxRef, indeterminate]);

  return (
    <label htmlFor={id} aria-disabled={disabled} className={styles['label']} style={style}>
      <div className='relative'>
        <input ref={checkboxRef} type='checkbox' id={id} onClick={handleClick} checked={selected} />
        {selected && (
          <Icon
            icon='tick'
            color='gradient'
            size='medium'
            style={{ position: 'absolute', top: 5.5, left: 4 }}
          />
        )}
      </div>

      <span>{text}</span>
    </label>
  );
};

export default Checkbox;
