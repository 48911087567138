import {
  ACCOUNT_DELETED,
  AUTH_ERROR,
  CREATE_ORGANIZATION_AND_LINK_TO_HOLDING,
  CREATE_USER_RECOMMENDATION,
  GET_PENDING_TASKS,
  GET_TOTAL_IMPACTS_FAIL,
  GET_TOTAL_IMPACTS_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REMOVE_ORGANIZATION_FROM_USER,
  REMOVE_USER_RECOMMENDATION,
  SET_GROUPINGS,
  SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS,
  SWITCH_GROUPING,
  SWITCH_ORGANIZATION,
  UPDATE_ORGANIZATION_DESCRIPTION,
  UPDATE_ORGANIZATION_FISCAL_DATA,
  UPDATE_ORGANIZATION_INVOICING_DATA,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_USER_SIGN_IN_COUNT,
  UPLOAD_IMAGE_PROFILE,
  USER_LOADED
} from '../actions/types';
import { Organization, OrganizationRecommendation } from '../types/entities/organization';
import { User } from '../types/entities/user';

type AuthState = {
  token: string | null;
  isAuthenticated: boolean | null;
  loading: boolean;
  user: User | null;
};
type AuthActions = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
};

const initialUser = localStorage.getItem('user')
  ? JSON.parse(localStorage.getItem('user') as string)
  : null;

const initialState: AuthState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loading: true,
  user: initialUser
};

const setOrganizationLimitOfficialSuppliersBonus = (
  state: AuthState,
  payload: {
    organization_id: string;
    limit_official_suppliers_bonus: string;
  }
) => {
  return {
    ...state,
    user: {
      ...state.user,
      organizations: state.user?.organizations?.map((organization) => {
        if (organization.id === payload.organization_id) {
          return {
            ...organization,
            limit_official_suppliers_bonus: payload.limit_official_suppliers_bonus
          };
        }
        return organization;
      })
    }
  };
};

export default function auth(state: AuthState = initialState, action: AuthActions) {
  const { type, payload } = action;
  switch (type) {
    case SET_ORG_LIMIT_OFFICIAL_SUPPLIERS_BONUS: {
      return setOrganizationLimitOfficialSuppliersBonus(state, payload);
    }
    case SWITCH_ORGANIZATION: {
      const { id, reporting_periods } = payload;

      if (!state.user || !state.user.organizations) {
        console.error('User or organizations not found in state.');
        return state; // Return the current state unchanged
      }

      // Find the organization that matches the selected ID
      const organization = state.user?.organizations?.find(
        (organization) => organization.id === id
      );

      // Update the organizations array with reporting_periods for the selected organization
      const updatedOrganizations = state.user.organizations.map((org) => {
        if (org.id === id) {
          return {
            ...org,
            reporting_periods // Add or update the reporting periods for the selected organization
          };
        }
        return org; // Keep other organizations unchanged
      });
      // Create the updated user object
      const organizationSwitch = {
        ...state.user,
        selectedOrganization: id,
        organizations: updatedOrganizations,
        is_group_fund: organization?.is_group_fund ?? false // Preserve existing logic
      };

      // Store the updated user object in localStorage
      localStorage.setItem('user', JSON.stringify(organizationSwitch));
      return { ...state, user: organizationSwitch };
    }
    case USER_LOADED:
    case REGISTER_SUCCESS: {
      const { selectedOrganization, reporting_periods, ...userData } = payload;

      // Clone the user and organizations
      const updatedOrganizations = userData.organizations.map((organization: Organization) => {
        // Only update the selected organization with reporting_periods
        if (organization.id === selectedOrganization) {
          return {
            ...organization,
            reporting_periods
          };
        }
        return organization;
      });

      const userInfo = {
        ...userData,
        selectedOrganization,
        organizations: updatedOrganizations,
        is_group_fund:
          updatedOrganizations.find((org: Organization) => org.id === selectedOrganization)
            ?.is_group_fund ?? false,
        selectedGrouping: '',
        tasks: {
          page: 1,
          size: 100,
          total: 0,
          total_completed: 0,
          items: []
        }
      };
      // Store the updated user object in localStorage
      localStorage.setItem('user', JSON.stringify(userInfo));
      localStorage.setItem('x-organization-id', selectedOrganization);

      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: userInfo
      };
    }
    case UPLOAD_IMAGE_PROFILE: {
      const profileImgUserInfo = {
        ...state.user,
        profile_img_url: payload.profile_img_url
      };
      localStorage.setItem('user', JSON.stringify(profileImgUserInfo));

      return {
        ...state,
        user: {
          ...state.user,
          profile_img_url: payload.profile_img_url
        }
      };
    }
    case CREATE_USER_RECOMMENDATION: {
      let recommendations: OrganizationRecommendation[] = [];
      let newOrganizations: Organization[] = [];

      if (state.user?.organizations) {
        newOrganizations = [...state.user.organizations];
      }
      const foundOrgIndex = newOrganizations.findIndex(
        (org) => org.id === state.user?.selectedOrganization
      );
      if (foundOrgIndex > -1) {
        recommendations = [...newOrganizations[foundOrgIndex].recommendations];
      }
      recommendations.push(payload);
      newOrganizations[foundOrgIndex].recommendations = recommendations;
      const recommendationsUserInfo = {
        ...state.user,
        organizations: newOrganizations
      };

      localStorage.setItem('user', JSON.stringify(recommendationsUserInfo));

      return {
        ...state,
        user: {
          ...state.user,
          organizations: newOrganizations
        }
      };
    }
    case REMOVE_USER_RECOMMENDATION: {
      let recommendations: OrganizationRecommendation[] = [];
      let newOrganizations: Organization[] = [];

      if (state.user?.organizations) {
        newOrganizations = [...state.user.organizations];
      }
      const foundOrgIndex = newOrganizations.findIndex(
        (org) => org.id === state.user?.selectedOrganization
      );
      if (foundOrgIndex > -1) {
        recommendations = [...newOrganizations[foundOrgIndex].recommendations];
      }
      const foundIndex = recommendations.findIndex((elem) => elem.id === payload.id);
      if (foundIndex >= 0) {
        recommendations.splice(foundIndex, 1);
      }
      newOrganizations[foundOrgIndex].recommendations = recommendations;
      const recommendationsUserInfo = {
        ...state.user,
        organizations: newOrganizations
      };

      localStorage.setItem('user', JSON.stringify(recommendationsUserInfo));

      return {
        ...state,
        user: {
          ...state.user,
          organizations: newOrganizations
        }
      };
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      if (state.user?.organizations) {
        const organizationToUpdateIndex = state.user?.organizations?.findIndex(
          (org) => org.id === payload.id
        );
        const oldOrganizations = [...state.user.organizations];
        if (organizationToUpdateIndex >= 0) {
          oldOrganizations[organizationToUpdateIndex] = {
            ...oldOrganizations[organizationToUpdateIndex],
            ...payload,
            role: oldOrganizations[organizationToUpdateIndex].role,
            total_impacts: oldOrganizations[organizationToUpdateIndex].total_impacts
          };
          localStorage.setItem(
            'user',
            JSON.stringify({ ...state.user, organizations: oldOrganizations })
          );
          return {
            ...state,
            user: {
              ...state.user,
              organizations: oldOrganizations
            }
          };
        }
      }
      return {
        ...state
      };
    }
    case GET_TOTAL_IMPACTS_SUCCESS: {
      if (state.user?.organizations) {
        const organizationToUpdateIndex = state.user?.organizations?.findIndex(
          (org) => org.id === payload.organization_id
        );
        const oldOrganizations = JSON.parse(JSON.stringify(state.user?.organizations));
        if (organizationToUpdateIndex >= 0) {
          oldOrganizations[organizationToUpdateIndex].total_impacts = payload.total_impacts;
          localStorage.setItem(
            'user',
            JSON.stringify({ ...state.user, organizations: oldOrganizations })
          );

          return {
            ...state,
            user: {
              ...state.user,
              organizations: oldOrganizations
            }
          };
        }
      }
      return {
        ...state
      };
    }
    case UPDATE_ORGANIZATION_DESCRIPTION: {
      if (state.user?.organizations) {
        const organizationToUpdateIndex = state.user?.organizations?.findIndex(
          (org) => org.id === payload.id
        );
        const oldOrganizations = [...state.user.organizations];
        if (organizationToUpdateIndex >= 0) {
          oldOrganizations[organizationToUpdateIndex] = {
            ...oldOrganizations[organizationToUpdateIndex],
            description: payload.description
          };
          localStorage.setItem(
            'user',
            JSON.stringify({ ...state.user, organizations: oldOrganizations })
          );
          return {
            ...state,
            user: {
              ...state.user,
              organizations: oldOrganizations
            }
          };
        }
      }
      return {
        ...state
      };
    }
    case UPDATE_ORGANIZATION_FISCAL_DATA: {
      if (state.user?.organizations) {
        const organizationToUpdateIndex = state.user.organizations.findIndex(
          (org) => org.id === payload.id
        );

        if (organizationToUpdateIndex >= 0) {
          // Clone the current organization and reporting_periods array
          const organization = { ...state.user.organizations[organizationToUpdateIndex] };
          const updatedReportingPeriods = organization.reporting_periods
            ? [...organization.reporting_periods]
            : [];

          // Find the index of the fiscal period to update within reporting_periods
          const fiscalPeriodIndex = updatedReportingPeriods.findIndex(
            (period) => period.name === payload.fiscalData.name
          );

          if (fiscalPeriodIndex >= 0) {
            // Update the specific fiscal period
            updatedReportingPeriods[fiscalPeriodIndex] = payload.fiscalData;
          } else {
            // If fiscal period not found, add it as a new entry
            updatedReportingPeriods.push(payload.fiscalData);
          }

          // Update the organization with the new reporting_periods array
          const updatedOrganization = {
            ...organization,
            reporting_periods: updatedReportingPeriods
          };

          // Clone the organizations array and update the specific organization
          const updatedOrganizations = [...state.user.organizations];
          updatedOrganizations[organizationToUpdateIndex] = updatedOrganization;

          // Update localStorage and return the new state
          localStorage.setItem(
            'user',
            JSON.stringify({ ...state.user, organizations: updatedOrganizations })
          );

          return {
            ...state,
            user: {
              ...state.user,
              organizations: updatedOrganizations
            }
          };
        }
      }
      return { ...state };
    }

    case UPDATE_ORGANIZATION_INVOICING_DATA: {
      if (state.user?.organizations) {
        const organizationToUpdateIndex = state.user.organizations.findIndex(
          (org) => org.id === payload.id
        );

        if (organizationToUpdateIndex >= 0) {
          // Clone the current organization and update invoicing data
          const organization = { ...state.user.organizations[organizationToUpdateIndex] };
          const updatedOrganization = {
            ...organization,
            invoicing_country: payload.invoicingData.invoicing_country,
            invoicing_trade_name: payload.invoicingData.invoicing_trade_name,
            invoicing_street_address: payload.invoicingData.invoicing_street_address,
            invoicing_postal_code: payload.invoicingData.invoicing_postal_code,
            invoicing_province: payload.invoicingData.invoicing_province
          };

          // Clone the organizations array and update the specific organization
          const updatedOrganizations = [...state.user.organizations];
          updatedOrganizations[organizationToUpdateIndex] = updatedOrganization;

          // Update localStorage and return the new state
          localStorage.setItem(
            'user',
            JSON.stringify({ ...state.user, organizations: updatedOrganizations })
          );

          return {
            ...state,
            user: {
              ...state.user,
              organizations: updatedOrganizations
            }
          };
        }
      }
      return { ...state };
    }

    case CREATE_ORGANIZATION_AND_LINK_TO_HOLDING: {
      // add organization to user.organizations
      if (!state.user || !state.user.organizations) {
        return {
          ...state
        };
      }
      const newOrganizations = [
        ...state.user.organizations,
        { ...payload.organizationCreated, role: 'parent' }
      ];
      localStorage.setItem(
        'user',
        JSON.stringify({ ...state.user, organizations: newOrganizations })
      );

      return {
        ...state,
        user: {
          ...state.user,
          organizations: newOrganizations
        }
      };
    }
    case GET_TOTAL_IMPACTS_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
    case ACCOUNT_DELETED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null
      };
    case REGISTER_FAIL:
      return {
        ...state
      };
    case UPDATE_USER_SIGN_IN_COUNT:
      localStorage.setItem(
        'user',
        JSON.stringify({ ...state.user, sign_in_count: payload.sign_in_count })
      );
      return {
        ...state,
        user: {
          ...state.user,
          sign_in_count: payload
        }
      };
    case REMOVE_ORGANIZATION_FROM_USER: {
      if (!state.user || !state.user.organizations) {
        return {
          ...state
        };
      }

      const newOrganizations = [...state.user.organizations].filter((organization) => {
        return organization.id !== String(payload);
      });

      localStorage.setItem(
        'user',
        JSON.stringify({ ...state.user, organizations: newOrganizations })
      );

      return {
        ...state,
        user: {
          ...state.user,
          organizations: newOrganizations
        }
      };
    }
    case SWITCH_GROUPING: {
      localStorage.setItem('user', JSON.stringify({ ...state.user, selectedGrouping: payload }));
      return {
        ...state,
        user: {
          ...state.user,
          selectedGrouping: payload
        }
      };
    }
    case SET_GROUPINGS: {
      localStorage.setItem('user', JSON.stringify({ ...state.user, groupings: payload }));
      return {
        ...state,
        user: {
          ...state.user,
          groupings: payload
        }
      };
    }
    case GET_PENDING_TASKS: {
      localStorage.setItem('user', JSON.stringify({ ...state.user, tasks: payload }));
      return {
        ...state,
        user: {
          ...state.user,
          tasks: payload
        }
      };
    }
    default:
      return state;
  }
}
