import GHGLogo from 'assets/logos/ghg.png';
import CSRDLogo from 'assets/logos/csrd.png';
import MITECOLogo from 'assets/logos/miteco.png';
import ISO14064Logo from 'assets/logos/iso14064.png';
import SBTiLogo from 'assets/logos/sbti.png';
import ISO14001Logo from 'assets/logos/iso14001.png';
import CDPLogo from 'assets/logos/cdp.png';
import ECOVADISLogo from 'assets/logos/ecovadis.png';
import GHGLogoBlocked from 'assets/logos/ghgBlocked.png';
import CSRDLogoBlocked from 'assets/logos/csrdBlocked.png';
import MITECOLogoBlocked from 'assets/logos/mitecoBlocked.png';
import ISO14064LogoBlocked from 'assets/logos/iso14064Blocked.png';
import SBTiLogoBlocked from 'assets/logos/sbtiBlocked.png';
import ISO14001LogoBlocked from 'assets/logos/iso14001Blocked.png';
import CDPLogoBlocked from 'assets/logos/cdpBlocked.png';
import ECOVADISLogoBlocked from 'assets/logos/ecovadisBlocked.png';
const logoLogicReturner = (frameworkName: string, blocked: boolean) => {
  switch (frameworkName) {
    case 'ghg_protocol':
      return blocked ? GHGLogoBlocked : GHGLogo;
    case 'csrd':
      return blocked ? CSRDLogoBlocked : CSRDLogo;
    case 'MITECO':
      return blocked ? MITECOLogoBlocked : MITECOLogo;
    case 'ISO_14064':
      return blocked ? ISO14064LogoBlocked : ISO14064Logo;
    case 'SBTi':
      return blocked ? SBTiLogoBlocked : SBTiLogo;
    case 'ISO_14001':
      return blocked ? ISO14001LogoBlocked : ISO14001Logo;
    case 'CDP':
      return blocked ? CDPLogoBlocked : CDPLogo;
    case 'ECOVADIS':
      return blocked ? ECOVADISLogoBlocked : ECOVADISLogo;
    default:
      return GHGLogo;
  }
};
export default logoLogicReturner;
