import React, { useEffect, useState } from 'react';
import Modal from 'components/ui/modal/Modal';
import FrameworkCheckbox from './FrameworkCheckbox';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import logoLogicReturner from '../controlPanel/components/frameworkProgress/components/FrameworkDashboard/utils/logoLogicReturner';
import { useTranslation } from 'react-i18next';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
type FrameworksModalProps = {
  saveFrameworksHandler: () => void;
  modalOpen: boolean;
};

type FrameworkObject = {
  id: string;
  framework_id: string;
  plan_start_date: string;
  plan_end_date: string;
  purchased: boolean;
  visible: boolean;
  framework: {
    id: string;
    name: string;
    version: string;
  };
};
const FrameworksModal = ({ saveFrameworksHandler, modalOpen }: FrameworksModalProps) => {
  const { t } = useTranslation();
  const frameworksArray: FrameworkObject[] = [
    {
      id: 'ghg_protocol',
      framework_id: 'ghg_protocol',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ghg_protocol',
        name: 'ghg_protocol',
        version: '1.0'
      }
    },
    {
      id: 'csrd',
      framework_id: 'csrd',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'csrd',
        name: 'csrd',
        version: '1.0'
      }
    },
    {
      id: 'MITECO',
      framework_id: 'MITECO',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'MITECO',
        name: 'MITECO',
        version: '1.0'
      }
    },
    {
      id: 'ISO_14064',
      framework_id: 'ISO_14064',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ISO_14064',
        name: 'ISO_14064',
        version: '1.0'
      }
    },
    {
      id: 'SBTi',
      framework_id: 'SBTi',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'SBTi',
        name: 'SBTi',
        version: '1.0'
      }
    },
    {
      id: 'ISO_14001',
      framework_id: 'ISO_14001',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ISO_14001',
        name: 'ISO_14001',
        version: '1.0'
      }
    },
    {
      id: 'CDP',
      framework_id: 'CDP',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'CDP',
        name: 'CDP',
        version: '1.0'
      }
    },
    {
      id: 'ECOVADIS',
      framework_id: 'ECOVADIS',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ECOVADIS',
        name: 'ECOVADIS',
        version: '1.0'
      }
    },
    {
      id: 'ISO_50001',
      framework_id: 'ISO_50001',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ISO_50001',
        name: 'ISO_50001',
        version: '1.0'
      }
    },
    {
      id: 'ISO_45001',
      framework_id: 'ISO_45001',
      plan_start_date: new Date().toISOString().split('T')[0],
      plan_end_date: new Date().toISOString().split('T')[0],
      purchased: false,
      visible: false,
      framework: {
        id: 'ISO_45001',
        name: 'ISO_45001',
        version: '1.0'
      }
    }
  ];

  const [selectedFrameworks, setSelectedFrameworks] = useState<Record<string, FrameworkObject>>(
    JSON.parse(localStorage.getItem('selectedFrameworks') || '{}')
  );

  useEffect(() => {
    const existingFrameworks = JSON.parse(localStorage.getItem('selectedFrameworks') || '{}');
    const updatedFrameworks = frameworksArray.reduce((acc, framework) => {
      acc[framework.id] = existingFrameworks[framework.id] || framework;
      return acc;
    }, {} as Record<string, FrameworkObject>);

    setSelectedFrameworks(updatedFrameworks);
    localStorage.setItem('selectedFrameworks', JSON.stringify(updatedFrameworks));
  }, [frameworksArray]);

  const handleFrameworkChange = (framework: FrameworkObject) => {
    const updatedFrameworks = {
      ...selectedFrameworks,
      [framework.id]: {
        ...framework, // Use the provided framework object
        visible: !selectedFrameworks[framework.id]?.visible // Toggle the `visible` property
      }
    };

    setSelectedFrameworks(updatedFrameworks);
    localStorage.setItem('selectedFrameworks', JSON.stringify(updatedFrameworks));
  };
  return (
    <Modal.WithPortal
      show={modalOpen}
      onClose={saveFrameworksHandler}
      width='780px'
      maxWidth='780px'
      closeButton={false}
      closeOnOutsideClick={false}>
      <h1 className='font-24 weight-600'>
        {t('controlPanel.frameworkProgress.configureFrameworksModal.title')}
      </h1>
      <h2 className='font-14 weight-400 mb-12'>
        {t('controlPanel.frameworkProgress.configureFrameworksModal.description')}
      </h2>
      <FormWrapper className='gap-4'>
        {frameworksArray.map((framework) => (
          <FrameworkCheckbox
            key={framework.id}
            title={t(`dashboard.frameworks.${framework.id}.title`)}
            subtitle={t(`dashboard.frameworks.${framework.id}.subtitle`)}
            logo={logoLogicReturner(framework.id, false)}
            blocked={framework.purchased}
            checked={selectedFrameworks[framework.id]?.visible || false}
            onChange={() => handleFrameworkChange(framework)}
          />
        ))}
      </FormWrapper>
      <FormButtonSection className='mt-16'>
        <Button
          lookAndFeel='primary'
          text={t('controlPanel.frameworkProgress.configureFrameworksModal.save')}
          onClick={saveFrameworksHandler}
        />
      </FormButtonSection>
    </Modal.WithPortal>
  );
};

export default FrameworksModal;
